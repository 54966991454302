.recharts-default-tooltip {
    background: var(--color-background);
    border: 1px solid var(--color-grey);
    border-radius: var(--border-radius);
}

.recharts-cartesian-axis-ticks {
    padding-top: 10px;
}

.recharts-cartesian-grid-horizontal line {
    stroke: var(--color-grey);
}

.recharts-cartesian-axis-tick .recharts-cartesian-axis-tick-value {
    fill: var(--color-primary);
}

.recharts-cartesian-axis-tick .recharts-cartesian-axis-tick-line {
    stroke: var(--color-grey);
}

.recharts-cartesian-axis-line {
    stroke: var(--color-grey);
}